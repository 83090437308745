export const getCoordinates = (state) => {
    if ( state.location.selected ) {

        return state.location.selected.geometry.location;

    }
}

export const getHoveredLocation = (state) => {
    return state.location.hovered;
}

export const getSelectedLocation = (state) => {
    if ( state.location.selected ) {

        return state.location.selected.formatted_address;

    }
}

export const getCsrf = (state) => {
    return state.csrf;
}

export const getGqlToken = (state) => {
    return state.gqlNavigationToken;
}

export const getLogos = (state) => {
    return state.logos;
}

export const getNavigation = (state) => {
    if ( state.navigation ) {
        return Object.entries(state.navigation).map(entry => {
            let item = entry[1];
            return {
                ...item
            }
        })
        .filter(item => item.active);
    }
}

export const getPredictions = (state) => {
    if ( state.location.predictions) {

        return state.location.predictions;

    }
}

export const getSchools = (state) => {
    if ( state.schools ) {
        return state.schools;
    }
}

export const getSearch = (state) => {
    return state.search;
}

export const getResults = (state) => {
    return state.results;
}

export const getMobileNavigation = (state) => {
    return state.mobileNavOpen;
}

export const getQueries = (state) => {
    return state.queries;
}

export const getPlaceholders = (state) => {
    return state.placeholders;
}

export const getPopUnders = (state) => {
    if( state.popUnders ) {
        return state.popUnders
    }

    return []
}