// Configure the metaseed api endpoint
export const configureMetaseedApi = (url) => ({
    baseURL: url,
    timeout: 6000,
});

// Execute a Metaseed query by sending an XHR to our api endpoint
export const executeMetaseedQuery = async(api, options, callback) => {
    // Execute the GQL query
    try {
        const response = await api.get('', {
            params: {
                lng: options.lng,
                lat: options.lat,
                limit: 6,
            }     
        })

        if (callback && response.data) {
            callback(response.data);
        }

        // Log any errors
        if (response.errors) {
            console.error(response.errors);
        }

    } catch (error) {
        // TODO: fetch all tutors after 6s
        console.error(error);
    }
};