export const SET_EVENTS = (state, data) => {
    if (data?.options?.reset) {
        state.items = data.events
    } else {
        state.items = [...state.items, ...data.events]
    }
}

export const SET_TOTAL = (state, data) => {
    state.total = data
}

export const SET_RESULTS = (state, data) => {
    state.results = data
}

export const SET_TYPES = (state, data) => {
    state.types = data
}

export const SET_GUIDANCE_REPORTS = (state, data) => {
    state.guidanceReports = data
}

export const SET_THEMES = (state, data) => {
    state.themes = data
}

export const SET_LOGOS = (state, data) => {
    state.logos = data
}

export const SET_FILTERS = (state, data) => {

    if ( data ) { 

        if ( data.id ) {
            state.filters[data.filter] = { 
                id: data.id,
                value: data.value,
            }
        } else {
            state.filters[data.filter] = null
        }

    } else { 

        // reset to initial state
        state.filters = {
            types: null,
            themes: null,
            guidanceReports: null,
        }

    }

}

export const EVENT_LOADING = (state, data) => {
    state.events.loading = data
}

export const INCREASE_LOAD = (state) => {
    state.currentPage = state.currentPage + 1
    state.events.loading = true
}

export const RESET = (state) => {
    state.items = []
}