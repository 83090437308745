import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import * as actions from './actions.js';
import * as getters from './getters.js';
import * as mutations from './mutations.js';

/* ----------- NAVIGATION ----------- */
//import { NAVIGATION } from './navigation/navigationStore'

//const navigationState = createPersistedState({
//    paths: ['navigation.items', 'navigation.lastUpdated', 'navigation.token', 'navigation.event', 'navigation.branding'],
//    key: 'nav'
//})

/* ----------- EVENTS ----------- */
import { EVENTS } from './events/eventStore'

const eventState = createPersistedState({
    paths: [
        'events.items', 
        'events.guidanceReports', 
        'events.types', 
        'events.themes', 
        'events.filters', 
        'events.logos',
        'popUnders'
    ],
    key: 'events'
})

// Main Store
export const store = createStore({
    state: () => ({
        csrf: null,
        gqlToken: null,
        location: {
            predictions: null,
            selected: null,
            hovered: null,
        },
        navigation: null,
        schools: null,
        search: {
            active: false,
        },
        results: null,
        mobileNavOpen: false,
        placeholders: null,
        popUnders: [],
        queries: {
            location: null,
            search: null,
        },
        logos: null,
        siteId: null,
    }),
    actions,
    getters,
    mutations,
    modules: {
        events: EVENTS,
    },
    plugins: [eventState]
})
