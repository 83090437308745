
export const NAVIGATION_QUERY = `
    query ($site: [String], $types: [String]) {
        entries( section: "pages", level: 1, site: $site, type: $types ) {
            id,
            slug,
            title,
            url,
            ...on pages_contentPage_Entry {
                active,
                label,
            }
            ...on pages_landingPage_Entry {
                active,
                label,
            }
            ...on pages_newsPage_Entry {
                active,
                label,
            }
            ...on pages_eventPage_Entry {
                active,
                label,
            }
            ...on pages_contactPage_Entry {
                active,
                label,
            }
            ...on pages_classroomLanding_Entry {
                active,
                label,
            }
            ...on pages_classroomSubject_Entry {
                active,
                label,
            }
            children: children {
                id,
                slug,
                title,
                url,
                ...on pages_contentPage_Entry {
                    active,
                    label,
                }
                ...on pages_landingPage_Entry {
                    active,
                    label,
                }
                ...on pages_newsPage_Entry {
                    active,
                    label,
                }
                ...on pages_eventPage_Entry {
                    active,
                    label,
                }
                ...on pages_contactPage_Entry {
                    active,
                    label,
                },
                ...on pages_classroomLanding_Entry {
                    active,
                    label,
                }
                ...on pages_classroomSubject_Entry {
                    active,
                    label,
                }
                children: children {
                    id,
                    slug,
                    title,
                    url,
                    ...on pages_contentPage_Entry {
                        active,
                        label,
                    }
                    ...on pages_landingPage_Entry {
                        active,
                        label,
                    }
                    ...on pages_newsPage_Entry {
                        active,
                        label,
                    }
                    ...on pages_eventPage_Entry {
                        active,
                        label,
                    }
                    ...on pages_contactPage_Entry {
                        active,
                        label,
                    },
                    ...on pages_classroomLanding_Entry {
                        active,
                        label,
                    }
                    ...on pages_classroomSubject_Entry {
                        active,
                        label,
                    }
                }
            }
        }
    }
`

export const SOCIAL_MEDIA_QUERY = `
    query {
        globalSet(handle: "socialMedia") {
            ...on socialMedia_GlobalSet {
                socialMedia {
                    ...on socialMedia_BlockType {
                        id,
                        socialMediaType,
                        socialMediaUrl {
                            url,
                        }
                    }
                }
            }
        }
    }
`

export const SITE_SEARCH_QUERY = `
    query searchQuery($sections: [String], $needle: String!, $limit: Int)
        {
            entries(site: "*", section: $sections, search: $needle, limit: $limit) {
            id,
            title,
            url,
            siteId,
            sectionHandle,
        }
    }
`;

export const PLACEHOLDERS_QUERY =
`
    query {
        globalSet(handle: "placeholders") {
            ...on placeholders_GlobalSet {
                brandingPlaceholder {
                    ...on branding_Asset {
                        optimizeBrandingImages {
                            src,
                            srcset,
                            srcWebp,
                            srcsetWebp,
                            placeholderImage,
                        }
                    }
                },
            }
        }
    }
`