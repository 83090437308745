export const sortEvents = (events) => {

    let items = Object.entries(events).map(entry => {
        let item = entry[1]
        return {
            id: item.id,
            siteId: item.siteId,
            title: item.title,
            url: item.url,
            description: item.description,
            type: item.type,
            eventCosts: item.eventCosts,
            eventCategories: item.eventCategories,
            guidanceReportCategories: item.guidanceReportCategories,
            themes: item.themes,
            typeFilter: item.eventCategories.map(cat => cat.id),
            guidanceReportFilter: item.guidanceReportCategories.map(cat => cat.id),
            themeFilter: item.themes.map(cat => cat.id),
            eventDates: item.eventDates.map(entry => {
                return {
                    dateFormat: entry.dateFormat,
                    startTimeFormat: entry.startTimeFormat,
                    endTimeFormat: entry.endTimeFormat,
                    date: new Date(entry.startDateTime + 'T' + entry.startTime + entry.timezone),
                    startTime: entry.startTime,
                    endTime: entry.endTime,
                    timestamp: new Date(entry.startDateTime + 'T' + entry.startTime + entry.timezone).getTime()
                }
            })
            .sort((a,b) => { return parseInt(a.timestamp) - parseInt(b.timestamp) })
            .filter(date => date.timestamp > Date.now()),
        }
    })
    .filter(item => item.eventDates.length > 0)

    return items.map(entry => {
        return {
            ...entry,
            current: entry.eventDates[0],
            upcoming: entry.eventDates.length > 1 ? entry.eventDates[1] : null
        }
    })
    .sort((a,b) => { return parseInt(a.current.timestamp) - parseInt(b.current.timestamp) })

}

export const usedThemes = (events) => {

    let themes = Object.entries(events).map(entry => {
        let event = entry[1]
        return event.themes
    })
    .filter( theme => theme.length > 0 )
    .map( theme => {
        return theme[0]
    })

    return themes

}

export const filterEvents = (events, filters) => {

    let filteredEvents = events

    if ( filters.types ) {

        filteredEvents = filteredEvents.filter( event => event.typeFilter.includes(filters.types.id) )
    }

    if ( filters.guidanceReports ) {

        filteredEvents = filteredEvents.filter( event => event.guidanceReportFilter.includes(filters.guidanceReports.id) )
    }

    if ( filters.themes ) {

        filteredEvents = filteredEvents.filter( event => event.themeFilter.includes(filters.themes.id) )
    }

    return filteredEvents

}