export const INIT_STATE = `
    query (
        $site: [QueryArgument], 
        $date: [QueryArgument], 
        $types: [QueryArgument],
        $guidanceReports: [QueryArgument],
        $themes: [QueryArgument],
        $offset: Int, 
        $limit: Int
    ) {

        events: entries(
            section: "events", 
            siteId: $site, 
            nextUpcomingEvent: $date,
            orderBy: "nextUpcomingEvent ASC"
            eventCategories: $types
            guidanceReportCategories: $guidanceReports
            themes: $themes
            limit: $limit
            offset: $offset
        ) {
            id
            title
            type: typeHandle
            siteId,
            url,
            ...on events_onlineEvent_Entry {
                description
                nextUpcomingEvent
                eventCosts {
                    ...on eventCosts_prices_BlockType {
                        pricePoint
                    }
                }
                eventDates: eventDatesTimeOnline {
                    ...on eventDatesTimeOnline_eventDate_BlockType {
                        startDateTime @formatDateTime(format: "Y-m-d")
                        startTime @formatDateTime(format: "H:i:s")
                        endTime @formatDateTime(format: "H:i:s")
                        timezone: startDateTime @formatDateTime(format: "P")
                        dateFormat: startDateTime @formatDateTime(format: "jS F Y")
                        startTimeFormat: startTime @formatDateTime(format: "g:ia")
                        endTimeFormat: endTime @formatDateTime(format: "g:ia")
                    }
                }
                eventCategories {
                    id
                    title
                }
                themes {
                    id
                    title
                }
                guidanceReportCategories {
                    id
                    title
                }
            }
            ...on events_locationEvent_Entry {
                description
                nextUpcomingEvent
                eventCosts {
                    ...on eventCosts_prices_BlockType {
                        pricePoint
                    }
                }
                eventDates: eventDatesTime {
                    ...on eventDatesTime_eventDate_BlockType {
                        startDateTime @formatDateTime(format: "Y-m-d")
                        startTime @formatDateTime(format: "H:i:s")
                        endTime @formatDateTime(format: "H:i:s")
                        timezone: startDateTime @formatDateTime(format: "P")
                        dateFormat: startDateTime @formatDateTime(format: "jS F Y")
                        timeFormat: startTime @formatDateTime(format: "g:ia")
                        startTimeFormat: startTime @formatDateTime(format: "g:ia")
                        endTimeFormat: endTime @formatDateTime(format: "g:ia")
                    }
                }
                eventCategories {
                    id
                    title
                }
                themes {
                    id
                    title
                }
                guidanceReportCategories {
                    id
                    title
                }
            }
            ...on events_hybridEvent_Entry {
                description
                nextUpcomingEvent
                eventCosts {
                    ...on eventCosts_prices_BlockType {
                        pricePoint
                    }
                }
                eventDates: eventHybridDatesTime {
                    ...on eventHybridDatesTime_eventDate_BlockType {
                        startDateTime @formatDateTime(format: "Y-m-d")
                        startTime @formatDateTime(format: "H:i:s")
                        endTime @formatDateTime(format: "H:i:s")
                        timezone: startDateTime @formatDateTime(format: "P")
                        dateFormat: startDateTime @formatDateTime(format: "jS F Y")
                        startTimeFormat: startTime @formatDateTime(format: "g:ia")
                        endTimeFormat: endTime @formatDateTime(format: "g:ia")
                    }
                }
                eventCategories {
                    id
                    title
                }
                themes {
                    id
                    title
                }
                guidanceReportCategories {
                    id
                    title
                }
            }
        }

        network: globalSets(handle: "networkSettings", site: ["*"]) {
            siteId,
            ...on networkSettings_GlobalSet {
                networkLogo {
                    title
                    ...on branding_Asset {
                        optimizeBrandingImages {
                        src,
                        srcset,
                        srcWebp,
                        srcsetWebp,
                        placeholderImage,
                        }
                    }
                }
                name
            }
        }

        types: entries(section: "eventCategories") {
            id,
            title,
        }

        guidanceReports: entries(section: "guidanceReportCategories") {
            id,
            title,
        }

        themes: entries(section: "themes") {
            id,
            title,
        }
        
        entryCount(
            section: "events", 
            siteId: $site, 
            nextUpcomingEvent: $date,
            orderBy: "nextUpcomingEvent ASC"
            eventCategories: $types
            guidanceReportCategories: $guidanceReports
            themes: $themes
        )
    }
`