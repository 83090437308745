export const setGqlToken = (state, data) => {
    state.gqlToken = data;
}

export const setCsrf = (state, data) => {
    state.csrf = data;
}

export const setHoveredSchool = (state, data) => {
    state.location.hovered = data;
}

export const setLocation = (state, data) => {
    state.location.selected = data;
}

export const setLocationQuery = (state, data) => {
    state.queries.location = data;
}

export const setLogos = (state, data) => {
    state.logos = data;
}

export const setNavigation = (state, data) => {
    state.navigation = data;
}

export const setPredictions = (state, data) => {
    state.location.predictions = data;
}

export const setResults = (state, data) => {
    state.results = data;
}

export const setSchools = (state, data) => {
    state.schools = data;
}

export const setSchoolQuery = (state, data) => {
    state.queries.school = data;
}

export const setSearchQuery = (state, data) => {
    state.queries.search = data;
}

export const setSearch = (state, data) => {
    state.search = data;
}

export const setMobileNavigation = (state, data) => {
    state.mobileNavOpen = data;
}

export const setPlaceholders = (state, data) => {
    state.placeholders = data;
}

export const setSiteId = (state, data) => {
    state.siteId = data
}

export const setPopUnder = (state, data) => {
    state.popUnders.push(data)
}