import * as eventActions from './eventActions.js';
import * as eventGetters from './eventGetters.js';
import * as eventMutations from './eventMutations.js';

export const EVENTS = {
    namespaced: true,
    state: () => ({
        items: [],
        results: null,
        total: null,
        limit: 20,
        currentPage: 0,
        guidanceReports: {},
        types: {},
        themes: {},
        logos: {},
        filters: {
            types: null,
            themes: null,
            guidanceReports: null,
        },
        events: {
            loading: false,
        }
    }),
    actions: eventActions,
    getters: eventGetters,
    mutations: eventMutations,
}