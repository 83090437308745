import axios from 'axios';
import { sortEvents, filterEvents } from '@/js/utils/events.js'
import { configureGqlApi, executeGqlQuery } from '@/js/utils/gql.js';
import { INIT_STATE } from '@/js/data/eventQueries.js';

const GRAPHQL_ENDPOINT = '/api';

// Fetch the filters
export const FETCH_INIT_STATE = async({commit, state, rootState}, options) => {

    if (options?.reset ) {
        state.items = []
    }

    const token = rootState.gqlToken ? rootState.gqlToken.token : null;
    const api = axios.create(configureGqlApi(GRAPHQL_ENDPOINT, token))

    let variables = {
        site: rootState.siteId === 2 ? '*' : rootState.siteId,
        date: `>=${new Date().toISOString().slice(0, 10)}`,
        limit: state.limit,
        offset: state.limit * state.currentPage,
        types: state.filters.types?.id ?? null,
        guidanceReports: state.filters.guidanceReports?.id ?? null,
        themes: state.filters.themes?.id ?? null
        // types: state.filters.types ? state.filters.types.map(type => type.id) : null,
        // guidanceReports: state.filters.guidanceReports ? state.filters.guidanceReports.map(report => report.id) : null,
        // themes: state.filters.themes ? state.filters.themes.map(theme => theme.id) : null
    }

    await executeGqlQuery(api, INIT_STATE, variables, (payload) => {

        if ( payload && payload.events ) {
            // commit('SET_EVENTS', payload.events)
            commit('SET_EVENTS', {events: sortEvents(payload.events), options: options})
        }
        
        if ( payload && payload.network ) {
            commit('SET_LOGOS', payload.network)
        }

        if ( payload && payload.types ) {
            commit('SET_TYPES', payload.types) 
        }

        if ( payload && payload.guidanceReports ) {
            commit('SET_GUIDANCE_REPORTS', payload.guidanceReports)
        }
        
        if ( payload && payload.themes ) {
            commit('SET_THEMES', payload.themes)
        }

        if ( payload && payload.entryCount ) {
            commit('SET_TOTAL', payload.entryCount)
        }

        state.events.loading = false
    })
}

export const FILTER_EVENTS = async({dispatch}) => {
    dispatch('FETCH_INIT_STATE', { reset: true } )
    // await commit('SET_RESULTS', filterEvents(state.items, state.filters))
}

export const LOAD_MORE_EVENTS = async({commit, dispatch}, options) => {
    commit('INCREASE_LOAD')
    dispatch('FETCH_INIT_STATE', options)
}