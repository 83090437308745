<script>

    import { defineAsyncComponent, defineComponent } from 'vue'
    import { mapGetters } from 'vuex'

    const formieLoaded = new Promise((resolve) => document.addEventListener('onFormieInit', resolve));

    export default defineComponent({

        components: {
            'bar--pop-under': defineAsyncComponent(() => import(/* webpackChunkName: "navigation--main" */ '@/vue/molecules/bars/bar--pop-under.vue')),
            'list--schools': defineAsyncComponent(() => import(/* webpackChunkName: "school--locator" */ '@/vue/organisms/lists/list--schools.vue')),
            'locator--map': defineAsyncComponent(() => import(/* webpackChunkName: "school--locator" */ '@/vue/organisms/locators/locator--map.vue')),
            'locator--school': defineAsyncComponent(() => import(/* webpackChunkName: "school--locator" */ '@/vue/organisms/locators/locator--school.vue')),
            'notification--cookie': defineAsyncComponent(() => import(/* webpackChunkName: "notification--cookie" */ '@/vue/molecules/notifications/notification--cookie.vue')),
            'navigation--main': defineAsyncComponent(() => import(/* webpackChunkName: "navigation--main" */ '@/vue/organisms/navigations/navigation--main.vue')),
            'view--events': defineAsyncComponent(() => import(/* webpackChunkName: "events" */ '@/vue/organisms/views/view--events.vue')),
            'media--video': defineAsyncComponent(() => import('@/vue/molecules/media/media--video.vue')),
        },

        computed: {
            ...mapGetters(['getCsrf', 'getGqlToken', 'getPlaceholders', 'getLogos']),
        },

        data: () => ({}),

        methods: {

            printPage() {
                window.print();
            }

        },

        mounted: async () => {
            await formieLoaded;

            window.Formie.initForms();
        },

        async created(){

            // Get the CSRF param to verify submissions before attempting any other queries
            if (!this.getCsrf) {
                await this.$store.dispatch("fetchCsrf");
            }

            // Wait for the GQL token before attempting GQL queries
            if (!this.getGqlToken) {
                await this.$store.dispatch("fetchGqlToken");
            }

            if ( !this.getLogos ) {
                await this.$store.dispatch("fetchLogos");
            }

            if (!this.getPlacholders) {
                await this.$store.dispatch("fetchPlaceholders");
            }
        }

    })

</script>
