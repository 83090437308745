import { usedThemes } from '@/js/utils/events'

export const GET_ITEMS = (state) => {

    return state.items

    // if ( state.results ) {

    //     return state.results

    // } else if ( state.items ) {

    //     return state.items

    //     // return state.items.slice(0,20)

    // }

}

export const GET_TYPES = (state) => {
    return state.types
}

export const GET_THEMES = (state) => {
    return state.themes
}

export const GET_GUIDANCE_REPORTS = (state) => {
    return state.guidanceReports
}

export const GET_NETWORK_LOGOS = (state) => {
    return state.logos
}

export const GET_NETWORK_LOGO = (state) => (siteId) => {

    if ( state.logos ) {
        let schoolLogo = Object.entries(state.logos).map(entry => {
            let logo = entry[1]
            return {
                ...logo
            }
        })
        .filter(logo => logo.siteId === siteId);

        return schoolLogo[0]?.networkLogo[0]
    }

}

export const GET_FILTERS = (state) => {
    if ( state.filters ) {
        return state.filters
    }
}

export const GET_EVENTS = (state) => {
    if ( state.events ) {
        return state.events
    }
}

export const GET_TOTAL = (state) => {
    return state.total
}