import axios from 'axios';
import { configureXhrApi, executeXhr } from '../utils/xhr.js';
import { configureGqlApi, executeGqlQuery } from '../utils/gql.js';
import { configureMetaseedApi, executeMetaseedQuery } from '../utils/metaseed.js';
import { NAVIGATION_QUERY, SITE_SEARCH_QUERY, PLACEHOLDERS_QUERY } from '../data/queries';

const CSRF_ENDPOINT = '/actions/site-module/csrf/get-csrf';
const TOKEN_ENDPOINT = '/actions/site-module/csrf/get-gql-token';
const GRAPHQL_ENDPOINT = '/api';

// const METASEED_RSN = 'https://api.v2.metaseed.io/rsn/';
const METASEED_RSN = 'https://metaseed-api.educationendowmentfoundation.org.uk/rsn/';

const PLACES_ENDPOINT = '/actions/site-module/places/get-places';
const PLACE_DETAIL_ENDPOINT = '/actions/site-module/places/get-place-detail';

const LOGOS_ENDPOINT = '/actions/site-module/schools/get-logos';

// Fetch & commit the CSRF token
export const fetchCsrf = async({commit}) => {
    const api = axios.create(configureXhrApi(CSRF_ENDPOINT));
    let variables = {
    };
    // Execute the XHR
    await executeXhr(api, variables, (data) => {
        commit('setCsrf', data);
    });
};

// Fetch & commit search results
export const fetchEntries = async({commit, state}, options) => {
    const token = state.gqlToken ? state.gqlToken.token : null;

    // configure the API endpoint
    const api = axios.create(configureGqlApi(GRAPHQL_ENDPOINT, token));

    let variables = {
        needle: state.queries.search,
        sections: options.sections,
        limit: options.limit,
    }

    await executeGqlQuery(api, SITE_SEARCH_QUERY, variables, (data) => {
        if (data.entries) {
            commit('setResults', data.entries);
        }
    })
}

// Fetch & commit the GraphQL token
export const fetchGqlToken = async({commit, state}) => {
    const api = axios.create(configureXhrApi(TOKEN_ENDPOINT));
    let variables = {
        ...(state.csrf && { [state.csrf.name]: state.csrf.value }),
    };
    // Execute the XHR
    await executeXhr(api, variables, (data) => {
        commit('setGqlToken', data);
    });
};

// Fetch & commit the Predictions from Google Places API
export const fetchLogos = async({commit}) => {

    const api = axios.create(configureXhrApi(LOGOS_ENDPOINT));

    let variables = {
    };

    // Execute the XHR
    await executeXhr(api, variables, (data) => {
        commit('setLogos', data);
    });

}

/* // Vue - Craft CMS field mapping;
const additionalParamFields = {

};

// Push additional params
const pushAdditionalParam = (state, dataFieldName, dbFieldName, additionalParams) => {
    let fieldValue = state.searchForm ? state.searchForm[dataFieldName] || '' : '';
    if (fieldValue.length) {
        additionalParams.push({
            fieldName: dbFieldName,
            fieldValue: fieldValue,
        });
    }
}; */

// build the base toplevel state
export const fetchNavigation = async({commit, state}, options) => {
    const token = state.gqlToken ? state.gqlToken.token : null;

    // configure the API endpoint
    const api = axios.create(configureGqlApi(GRAPHQL_ENDPOINT, token));

    let variables = {
        site: options.site,
        types: [
            "contentPage",
            "landingPage",
            "newsPage",
            "newsletterPage",
            "eventPage",
            "contactPage",
            "classroomLanding",
            "classroomSubject"
        ]
    };

    // Execute the GQL Query
    await executeGqlQuery(api, NAVIGATION_QUERY, variables, (data) => {
        if (data.entries) {
            commit('setNavigation', data.entries);
        }
    })
}

// Fetch & commit the Predictions from Google Places API
export const fetchPlaces = async({commit, state}, options) => {

    const api = axios.create(configureXhrApi(PLACES_ENDPOINT));

    let variables = {
        ...(state.csrf && { [state.csrf.name]: state.csrf.value }),
        input: state.queries.location,
        components: 'country:gb',
    };

    // Execute the XHR
    await executeXhr(api, variables, (data) => {

        if (data.predictions) {
            commit('setPredictions', data.predictions);
        }

    });

}

// Fetch & commit the selected place to have lat/lng details
export const fetchPlace = async({commit, state}, options) => {
    const api = axios.create(configureXhrApi(PLACE_DETAIL_ENDPOINT));

    let variables = {
        ...(state.csrf && { [state.csrf.name]: state.csrf.value }),
        'place_id': options.id,
    }

    // Execute the XHR
    await executeXhr(api, variables, (data) => {

        if (data.result) {
            commit('setLocation', data.result);
        }

    });

}

// Fetch & commit the RSN Schools from metaseed base on location
export const fetchSchools = async({commit, state}, options) => {

    const api = axios.create(configureMetaseedApi(METASEED_RSN));

    // execute the metaseed schools query
    await executeMetaseedQuery(api, options, (data) => {

        if (data.data.schools) {
            commit('setSchools', data.data.schools);
        }
    })

}

// fetch placeholders
export const fetchPlaceholders = async({commit, state}) => {
    const token = state.gqlToken ? state.gqlToken.token : null;

    // configure the API endpoint
    const api = axios.create(configureGqlApi(GRAPHQL_ENDPOINT, token));

    let variables = {};

    // Execute the GQL Query
    await executeGqlQuery(api, PLACEHOLDERS_QUERY, variables, (data) => {
        if (data.globalSet) {
            commit('setPlaceholders', data.globalSet);
        }
    })
}


export const setPopUnder = async({commit}, payload) => {
    commit('setPopUnder', payload)
}
