// importing and setting up Font Awesome
import { dom, library } from '@fortawesome/fontawesome-svg-core';
import {
    faEnvelope as farEnvelope,
    faFileArchive as farFileArchive,
    faFileExcel as farFileExcel,
    faFilePdf as farFilePdf,
    faFilePowerpoint as farFilePowerPoint,
    faFileWord as farFileWord,
    faCalendarTimes as farCalendarTimes,
} from '@fortawesome/free-regular-svg-icons';

import {
    faAlignLeft as fasAlignLeft,
    faAngleDown as fasAngleDown,
    faAngleLeft as fasAngleLeft,
    faAngleRight as fasAngleRight,
    faAngleUp as fasAngleUp,
    faArchive as fasArchive,
    faBars as fasBars,
    faBookOpen as fasBookOpen,
    faBrain as fasBrain,
    faBullhorn as fasBullhorn,
    faCalculator as fasCalculator,
    faCalendarDay as fasCalendarDay,
    faCaretDown as fasCaretDown,
    faCircle as fasCircle,
    faCloudDownloadAlt as fasCloudDownloadAlt,
    faCommentAlt as fasCommentAlt,
    faDownload as fasDownload,
    faEllipsisV as fasEllipsisV,
    faEnvelope as fasEnvelope,
    faExternalLinkAlt as fasExternalLinkAlt,
    faFlask as fasFlask,
    faGlobeEurope as fasGlobeEurope,
    faHashtag as fasHashtag,
    faHome as fasHome,
    faLightbulb as fasLightbulb,
    faLongArrowAltLeft as fasLongArrowAltLeft,
    faMapMarkerAlt as fasMapMarkerAlt,
    faNewspaper as fasNewspaper,
    faPen as fasPen,
    faPhone as fasPhone,
    faPodcast as fasPodcast,
    faPrint as fasPrint,
    faProjectDiagram as fasProjectDiagram,
    faSearch as fasSearch,
    faShareAlt as fasShareAlt,
    faSquareFull as fasSquareFull,
    faStar as fasStar,
    faTimes as fasTimes,
    faQuestion as fasQuestion,
    faFilePdf as fasFilePdf,
} from '@fortawesome/free-solid-svg-icons';

import {
    faFacebookF as fabFacebookF,
    faInstagram as fabInstagram,
    faLinkedinIn as fabLinkedinIn,
    // faTwitter as fabTwitter,
    faVimeoV as fabVimeoV,
    faXTwitter as fabXTwitter,
    faYoutube as fabYoutube,
} from '@fortawesome/free-brands-svg-icons';

// load font-awesome libraries
library.add(
    fasBookOpen,
    fasBrain,
    fasCalculator,
    fasFlask,
    fasLightbulb,
    fabFacebookF,
    fabInstagram,
    fabLinkedinIn,
    // fabTwitter,
    fabVimeoV,
    fabXTwitter,
    fabYoutube,
    farCalendarTimes,
    farEnvelope,
    farFileArchive,
    farFileExcel,
    farFilePdf,
    farFilePowerPoint,
    farFileWord,
    fasAlignLeft,
    fasAngleDown,
    fasAngleLeft,
    fasAngleRight,
    fasAngleUp,
    fasArchive,
    fasBars,
    fasBullhorn,
    fasCalendarDay,
    fasCaretDown,
    fasCircle,
    fasCloudDownloadAlt,
    fasCommentAlt,
    fasDownload,
    fasEllipsisV,
    fasEnvelope,
    fasExternalLinkAlt,
    fasGlobeEurope,
    fasHashtag,
    fasHome,
    fasLongArrowAltLeft,
    fasMapMarkerAlt,
    fasNewspaper,
    fasFilePdf,
    fasPen,
    fasPhone,
    fasPodcast,
    fasPrint,
    fasProjectDiagram,
    fasQuestion,
    fasSearch,
    fasShareAlt,
    fasSquareFull,
    fasStar,
    fasTimes,
);

// convert i tags to SVG
dom.watch({
    autoReplaceSvgRoot: document,
    observeMutationsRoot: document.body
});
